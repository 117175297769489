.details {
    margin-top: 3rem;

    .details-header-skeleton {
        padding: 20px;
        background-color: #f6f8fa;
        display: flex;
        flex-direction: column;
        border-radius: 6px;

        .details-location {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .location-short {
            margin-bottom: 0.3rem;
        }

        .location-long {
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }

        .last-review-date {
            margin-top: 1.6rem;
            margin-bottom: 1rem;
        }
    }

    .post-review-button-section-skeleton {
        display: block;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 1.5rem;

        .text-section {
            .title {
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 24px;
                color: #000000;
            }

            .content {
                font-weight: 400;
                font-size: 1rem;
                line-height: 24px;
                color: #000000;
            }
        }

        .button-section {
            width: 100%;
        }
    }

    .accordion-section {
        margin-top: 1.5rem;
        margin-bottom: 3rem;
    }

    .tab-section {
        margin-top: 2rem;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .details {
        margin-top: 3rem;

        .details-header-skeleton {
            display: block;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .details {
        .post-review-button-section-skeleton {
            flex-direction: row;
            align-items: center;
            padding-bottom: 1rem;

            .text-section {
                margin-right: 2rem;
            }

            .button-section {
                width: 30%;
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .details {
        .details-header-skeleton {
            .location-short {
                margin-top: 0.7rem;
                margin-bottom: 0.6rem;
            }

            .last-review-date {
                margin-bottom: 0.7rem;
            }
        }

        .post-review-button-section-skeleton {
            flex-direction: row;
            align-items: center;
            padding-bottom: 1rem;

            .text-section {
                margin-right: 2rem;
            }

            .button-section {
                width: 13%;
            }
        }

        .tab-section {
            margin-top: 2rem;
        }
    }
}
