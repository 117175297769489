.input-label-text {
    font-weight: 500;
    margin-bottom: 10px;
}

.custom-form-control {
    border: 1px solid #ebebeb;
    height: 50px;
    margin-bottom: 18px;
    border-radius: 6px;
}

::placeholder {
    color: #bebebe !important;
}

.input-field-error {
    color: #e71d1d;
    margin-bottom: 18px;
    margin-top: -12px;
    font-size: 14px;
}
