.footer {
    background-color: #1e1e1e;
    padding-top: 5rem;
    padding-bottom: 2rem;
    color: #fff;

    .rights-section {
        margin-bottom: 1rem;

        .rights-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            // text-transform: uppercase;
            color: #fff;
            margin-bottom: 0.5rem;
        }

        .rights-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #fff;
        }
    }

    .link-section {
        .link-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #fff;
            text-decoration: underline;
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                margin-top: 16px;

                a {
                    text-decoration: none;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #fff;

                    &:hover {
                        text-decoration: underline;
                        color: #fff;
                    }
                }
            }
        }
    }

    .follow-us {
        .link-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #fff;
        }

        ul {
            list-style-type: none;
            padding-left: 0;
            display: flex;

            li {
                margin-right: 2rem;

                .follow-icon {
                    img {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .footer {
        .rights-section {
            margin-bottom: 2rem;
        }

        .follow-us {
            margin-top: 2rem;
        }

        .link-section {
            .link-title {
                text-decoration: none;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .footer {
        .rights-section {
            margin-bottom: 0rem;
        }

        .follow-us {
            margin-top: 0rem;
        }
    }
}
