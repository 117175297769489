.attribution {
    padding: 6rem 0 0 0;

    .attribution-title {
        font-size: 1.6rem;
        font-weight: 600;
    }

    .attribution-subtitle {
        font-size: 1rem;
        color: #000;
        margin-right: 1rem;
    }

    .attribution-section {
        margin-top: 2rem;
        
        h1 {
            font-size: 1.4rem;
            font-weight: 600;
        }
    }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
    .attribution {
        padding: 8rem 0 3rem 0;

        .attribution-subtitle {
            margin-right: 1rem;
            line-height: 1.9rem;
        }
    }
}
