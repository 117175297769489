.login {
    .login-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .login-content {
            padding: 4rem 1rem;
            border: 1px solid #ebebeb;
            border-radius: 6px;
            margin-bottom: 5rem;
            padding-bottom: 2rem;
            margin-top: 4rem;

            .login-header-section {
                .login-title {
                    font-weight: 600;
                    font-size: 20px;
                }

                .login-subtitle {
                    max-width: fit-content;
                    color: #454749;
                    line-height: 1.5rem;
                }
            }

            .login-custom-right-section {
                .login-form {
                    margin-top: 38px;

                    .input-label-text {
                        font-size: 0.9rem;
                    }

                    .custom-form-control::placeholder {
                        font-size: 0.9rem;
                    }

                    .forgot-password-link {
                        text-decoration: none;
                        color: #4576ff;
                        display: flex;
                        justify-content: flex-end;
                        font-size: 0.9rem;

                        &:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .login-button {
                        width: 100%;
                        margin-top: 20px;
                        padding: 0.6rem 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.9rem;

                        &:disabled {
                            background-color: #4576ff;
                            opacity: 0.6;
                        }
                    }
                }

                .divider {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 26px;

                    .divider-line {
                        height: 2px;
                        background-color: #ebebeb;
                        width: 45%;

                        &:first-child {
                            margin-right: 1rem;
                        }

                        &:last-child {
                            margin-left: 1rem;
                        }
                    }
                }

                .google-login {
                    .google-login-button {
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        padding: 0.8rem 0;
                        border: 1px solid #ebebeb;
                        border-radius: 6px;
                        margin-top: 26px;
                        background-color: #fff;
                        color: #454749;

                        &:hover {
                            cursor: pointer;
                        }

                        span {
                            margin-left: 10px;
                            font-size: 0.9rem;
                        }
                    }
                }

                .create-account {
                    margin-top: 26px;
                }

                .create-account-link {
                    text-decoration: none;
                    color: #4576ff;
                    display: flex;
                    justify-content: center;
                    font-size: 0.9rem;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                        color: #4576ff;
                    }

                    .create-account-link-color {
                        color: #4576ff;
                        margin-left: 0.3rem;
                    }
                }
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .login {
        .login-page {
            .login-content {
                margin-top: 6rem;
                padding: 4rem 3rem;
                min-width: 600px;
                max-width: 600px;
                padding-bottom: 2rem;

                .login-custom-right-section {
                    .login-form {
                        margin-top: 3rem;

                        .input-label-text {
                            font-size: 1rem;
                        }

                        .custom-form-control::placeholder {
                            font-size: 1rem;
                        }

                        .forgot-password-link {
                            font-size: 1rem;
                        }

                        .login-button {
                            font-size: 1rem;
                        }
                    }

                    .google-login {
                        .google-login-button {
                            span {
                                margin-left: 10px;
                                font-size: 1rem;
                            }
                        }
                    }

                    .create-account-link {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
