.about {
    padding: 6rem 0 0 0;

    .content-section {
        .content {
            margin-top: 1rem;
            font-size: 0.9rem;
            line-height: 2rem;
        }

        .about-image {
            width: 350px;
        }
    }
}

@media (min-width: 768px) { 
    .about {
        .content-section {
            .about-image {
                width: 100%;
            }
        }
    }
}


@media (min-width: 1200px) {
    .about {
        padding: 8rem 0 3rem 0;

        .content-section {
            .content {
                margin-top: 1rem;
                font-size: 1rem;
            }
        }
    }
}