.navbar {
    border-bottom: 1px solid #ebebeb !important;
    background-color: white !important;
    padding: 1rem 0;
}

.navbar-brand {
    a {
        font-weight: 600;
        font-size: 1.25rem;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        text-decoration: none;
        color: #4576ff;
    }
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin: 0 1rem;
    color: #898b8e;
}

.active-link {
    color: #4576ff !important;
}
