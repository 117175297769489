.form-tab-content-item {
    .input-section {
        .label-text {
            font-size: 0.9rem;
        }

        .required-field {
            color: #d66060;
            margin-left: 0.1rem;
        }

        .rating-section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
                span {
                    font-size: 1.1rem !important;
                }
            }
        }

        .comment-section {
            margin-top: 1.5rem;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            textarea {
                font-size: 0.9rem;
                margin-top: 0.3rem;
            }
        }

        .image-upload-section {
            display: flex;
            justify-content: space-between;
            margin-top: 3rem;

            .dropzone {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 10px;
                border-width: 2px;
                border-radius: 2px;
                border-color: #eeeeee;
                border-style: dashed;
                background-color: #fafafa;
                color: #bdbdbd;
                outline: none;
                transition: border 0.24s ease-in-out;
                margin-top: 0.3rem;

                p {
                    margin: 0;
                    font-size: 0.9rem;
                }
            }

            .review-image-preview {
                height: 300px !important;
                width: 300px !important;
            }

            .thumbsContainer {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 16px;
            }

            .thumb {
                display: inline-flex;
                border-radius: 2px;
                border: 1px solid #eaeaea;
                margin-bottom: 8px;
                margin-right: 8px;
                width: 100px;
                height: 100px;
                padding: 4px;
                box-sizing: border-box;
            }

            .thumbInner {
                display: flex;
                min-width: 0;
                overflow: hidden;
                position: relative;

                .remove-button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #ebebeb;
                    padding: 0.2rem 0.5rem;
                    border-bottom-left-radius: 6px;

                    &:hover {
                        cursor: pointer;
                        background-color: #d66060;
                        color: #fff;
                    }
                }

                .img {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
        }

        .button-section {
            display: flex;
            width: 100%;

            .next-button {
                // margin-left: 1rem;
                min-width: 6rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
    }

    .info-section {
        background: #f8f8f8;
        margin: 2rem 0;
        border-radius: 6px;
        padding: 20px 25px;
        height: fit-content;

        .info-title {
            font-weight: 500;
            font-size: 0.9rem;
            color: #000000;
        }

        .info-text {
            font-weight: 400;
            font-size: 0.89rem;
            margin-top: 0.5rem;
            color: #857f7f;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .form-tab-content-item {
        .input-section {
            margin-top: 1rem;

            .label-text {
                font-size: 0.99rem;
            }

            .image-upload-section {
                margin-top: 2rem;
            }

            .button-section {
                display: flex;
                width: 100%;
            }
        }
    }
}
