.contact {
    padding: 6rem 0 0 0;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.title {
    font-size: 1.6rem;
    font-weight: 600;
}

.subtitle {
    font-size: 1rem;
    color: #000;
}

.contactFormColumn {
    margin-top: 2rem;
}

.contactForm {
    border: 0.0625rem solid #ebebeb;
    padding: 2rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
}

.meetingButtonContainer {
    display: flex;
    justify-content: left;
    width: 100%;
}

.meetingButton {
    width: 60%;
    border-radius: 2.8125rem;
    background-color: #4576ff;
    color: #ffffff;
    font-size: 0.85em;
    letter-spacing: 0.06em;
    padding: 0.6rem 1.3rem;
    cursor: pointer;
    border: none;
    outline: none;
    text-align: center;
}

.meetingButton:hover {
    background-color: #4576ff;
    color: #fff;
    cursor: pointer;
}

.formContainer {
    padding: 1.5rem 0;
}

.inputField {
    margin-bottom: 2rem;
    font-size: 0.8em;
}

.messageInput {
    resize: none;
}

.sendButton {
    border-radius: 2.8125rem;
    background-color: #4576ff;
    color: #fff;
    text-transform: capitalize;
    font-size: 0.85em;
    letter-spacing: 0.06em;
    padding: 0.6rem 1.3rem;
    cursor: pointer;
    border: none;
    outline: none;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.rightSection {
    padding: 2rem 0 1rem 0;
}

.rightSectionTitle {
    font-weight: 700;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.rightSectionContentText {
    color: inherit;
}

.labelBox {
    margin: 1rem 0 2rem 0;
}

.label {
    font-weight: 600;
}

.meetingLabel {
    margin-bottom: 0.3rem;
}

.contactIcon {
    margin-right: 0.5rem;
    color: #4576ff;
}

.socialLink {
    text-decoration: none;
    color: #282c34;
}

.socialLink:hover {
    color: #4576ff;
}

.toastBody {
    background-color: #4576ff;
}

.toastContent {
    color: #fff;
}

.errorMessage {
    color: #f00;
}

@media (min-width: 768px) {
    .subtitle {
        font-size: 0.9rem;
    }

    .contactFormColumn {
        padding-right: 0;
    }

    .meetingButton {
        width: 30%;
    }
}

@media (min-width: 992px) {
    .contact {
        padding: 6rem 0 3rem 0;
    }

    .subtitle {
        font-size: 1rem;
    }

    .contactForm {
        padding: 2rem;
    }

    .followSectionColumn {
        padding-left: 2rem;
    }

    .inputField {
        font-size: 0.9rem;
        height: 2.8125rem;
    }

    .messageInput {
        height: 6.25rem;
    }

    .linkContainer {
        display: flex;
        justify-content: space-between;

        .labelValue {
            display: flex;
            align-items: center;
        }
    }

    .meetingButton {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .contact {
        padding: 8rem 0 3rem 0;
    }

    .subtitle {
        font-size: 1.1rem;
    }

    .contactFormColumn {
        margin-top: 3rem;
    }

    .contactForm {
        padding: 2rem 1rem;
    }

    .formContainer {
        padding: 1rem;
    }

    .inputField {
        margin-bottom: 2rem;
        font-size: 0.85em;
        // height: 2.8125rem;
        background-color: #f8f9fa;
    }

    .sendButton {
        padding: 0.6rem 2rem;
    }

    .rightSection {
        padding: 4rem 1rem;
    }

    .labelBox:nth-child(2) {
        margin-top: 2rem;
    }
}
