.property-link {
    text-decoration: none;

    .result-item {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ebebeb;
        box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        padding: 25px 20px;
        margin-top: 1rem;
        margin-bottom: 2rem;

        .detail-section {
            width: 100%;
        }

        .top-section {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .top-section-block {
            display: block;
            width: 100%;
        }

        .property-name {
            font-weight: 500;
            font-size: 1rem;
            color: #000000;
            // max-width: 13rem;
        }

        .property-location {
            font-weight: 400;
            font-size: 0.85rem;
            line-height: 19px;
            color: #000000;
            margin: 0;
            // max-width: 13rem;
        }

        .bottom-section {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 0.5rem;

            .property-star-rating {
                margin-top: -6px;

                div {
                    span {
                        font-size: 18px !important;
                    }
                }
            }

            .property-rating {
                background-color: #ebebeb;
                border-radius: 6px;
                padding: 1rem 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000000;
                font-weight: 600;
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .property-link {
        .result-item {
            .property-name {
                font-size: 1.2rem;
            }

            .property-location {
                font-size: 1rem;
            }

            .bottom-section {
                margin-top: 1.5rem;

                .property-star-rating {
                    div {
                        span {
                            font-size: 20px !important;
                        }
                    }
                }
            }
        }
    }
}
