.details {
    margin-top: 3rem;

    .details-header {
        padding: 20px;
        background-color: #f6f8fa;
        display: flex;
        flex-direction: column;
        border-radius: 6px;

        .details-location {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .location-short {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 0.3rem;
        }

        .location-long {
            font-size: 16px;
            line-height: 19px;
            color: #000000;
        }

        .last-review-date {
            margin-top: 1.6rem;
            margin-bottom: 1rem;
            font-weight: 300;
            font-size: 0.9rem;
            color: #000000;
        }

        .details-stats {
            background-color: #fff;
            padding: 35px 40px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .score-text {
                margin: 0;
                text-align: center;
                font-size: 0.9rem;
            }

            div {
                span {
                    font-size: 16px !important;
                }
            }

            .rating-value {
                font-weight: 600;
                font-size: 1rem;
                line-height: 24px;
                color: #000000;
                margin-bottom: 0.6rem;
            }

            .result-text {
                font-weight: 400;
                font-size: 16px;
                line-height: 15px;
                margin-top: 10px;
                color: #454749;
            }
        }
    }

    .post-review-button-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 1.5rem;

        .text-section {
            .title {
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 24px;
                color: #000000;
            }

            .content {
                font-weight: 400;
                font-size: 1rem;
                line-height: 24px;
                color: #000000;
            }
        }

        .button-section {
            width: 100%;

            .post-review-button {
                width: 100%;
            }
        }
    }

    .accordion-section {
        margin-top: 1.5rem;
        margin-bottom: 3rem;

        .accordion-header {
            button {
                background: #f6f8fa;
                color: #4576ff;
            }
        }

        .accordion-review-section {
            max-height: 250px;
            overflow: scroll;
        }

        .no-review-text {
            display: flex;
            justify-content: center;
            font-size: 0.9rem;
            margin: 1rem 0;
        }
    }

    .tab-section {
        margin-top: 2rem;

        .nav-link {
            color: #454749;
        }

        .nav-link.active {
            color: #4576ff;
        }

        .tab-section-title {
            margin: 2rem 0;
        }

        .review-section {
            margin-bottom: 3rem;

            .no-review-text {
                display: flex;
                justify-content: center;
                margin: 10rem 0;
            }
        }
    }
}

.details-loading-failed {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .details {
        margin-top: 3rem;

        .details-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .details {
        .details-stats {
            .score-text {
                max-width: 110px;
            }
        }
        .post-review-button-section {
            flex-direction: row;
            align-items: center;
            padding-bottom: 0rem;

            .text-section {
                margin-right: 2rem;
            }

            .button-section {
                width: 30%;
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .details {
        .details-header {
            .location-short {
                margin-bottom: 0.6rem;
            }

            .last-review-date {
                margin-bottom: 0;
            }
        }

        .post-review-button-section {
            flex-direction: row;
            align-items: center;
            padding-bottom: 0rem;

            .text-section {
                margin-right: 2rem;
            }

            .button-section {
                width: 25%;
            }
        }

        .tab-section {
            margin-top: 2rem;

            .nav-link {
                color: #454749;
            }

            .nav-link.active {
                color: #4576ff;
            }

            .tab-section-title {
                margin: 2rem 0;
            }

            .review-section {
                margin-bottom: 3rem;

                .no-review-text {
                    display: flex;
                    justify-content: center;
                    margin: 10rem 0;
                }
            }
        }
    }
}
