.post-review-modal {
    .post-review-header-section {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        .card-section {
            border: 1px solid #ebebeb;
            padding: 1rem 2rem;
            border-radius: 6px;
            margin-bottom: 1.5rem;

            .card-name {
                font-weight: 500;
                font-size: 20px;
                line-height: 28px;
                color: #000000;
            }

            .card-location {
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                color: #000000;
            }
        }

        .add-category-accordion {
            .accordion {
                .accordion-button {
                    font-size: 0.9rem;
                    line-height: 1.3rem;
                }

                .accordion-body {
                    padding: 0;
                }
            }
        }
    }

    .post-review-content-accordion-section {
        margin: 2rem 0;
    }

    .post-review-content-tab-section {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 3rem;
        padding-bottom: 2rem;

        .nav-link {
            color: #454749;
        }

        .nav-link.active {
            color: #4576ff;
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .post-review-modal {
        .post-review-header-section {
            .add-category-accordion {
                .accordion {
                    .accordion-button {
                        font-size: 0.99rem;
                        line-height: 1.5rem;
                    }

                    .accordion-body {
                        padding: 0;
                    }
                }
            }
        }
    }
}
