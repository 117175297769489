.search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 4px hsla(0, 0%, 75%, 0.14);
    padding: 0.5rem;
    box-shadow: 0px 6px 8px 4px rgba(190, 190, 190, 0.26);

    .search-icon {
        display: none;
    }

    .search-dropdown-container {
        width: 90%;
        margin-bottom: 1rem;
    }

    .search-input {
        margin: 0;
        margin-bottom: 1rem;
    }

    .home-search-button {
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
    }

    .home-clear-button {
        width: 100%;
        margin-top: 1rem;
        background-color: #fff;
        border: 1px solid #ebebeb;
        color: #454749;
    }

    .mobile-entity-control {
        height: 50px;
        border: 1px solid #ebebeb;
        border-radius: 6px;
    }
}

.search-form-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;

    .search-icon {
        display: none;
    }

    .search-dropdown-container {
        margin-bottom: 1rem;
    }

    .search-input {
        margin: 0;
        margin-bottom: 1rem;
    }

    .home-search-button {
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
    }

    .home-clear-button {
        width: 100%;
        margin-top: 1rem;
        background-color: #fff;
        border: 1px solid #ebebeb;
        color: #454749;
    }

    .mobile-entity-control {
        height: 50px;
        border: 1px solid #ebebeb;
        border-radius: 6px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .search-form {
        display: flex !important;
        flex-direction: row;

        .search-icon {
            display: flex;
        }
    }

    .search-form {
        display: flex;
        align-items: center;
        padding: 0.5rem;

        .search-icon {
            margin-left: 1rem;
            margin-right: 0.2rem;
        }

        .search-input {
            margin: 0;
            height: 65px;
            border: none;
            outline: none;
            padding-left: 0;
            margin-left: 1rem;

            &:focus {
                box-shadow: none;
            }
        }

        .clear-search {
            height: 26px;
            width: 26px;

            &:hover {
                cursor: pointer;
            }
        }

        .search-dropdown-container {
            width: 45%;
            margin-bottom: 0;
        }

        .home-search-button {
            width: 220px;
            height: 65px;
            margin-left: 1rem;
            font-weight: 500;
            font-size: 17px;
        }
    }
}
