.more-category-section {
    background: #f8f8f8;
    border-radius: 6px;
    padding: 20px;

    .more-category-title {
        font-weight: 500;
        font-size: 0.96rem;
        color: #000000;
    }

    .more-category-text {
        font-weight: 400;
        font-size: 0.93rem;
        margin-top: 0.5rem;
        color: #857f7f;
    }

    .category-form {
        display: flex;

        .category-input {
            margin: 0;
            margin-right: 1rem;
            font-size: 0.9rem;
        }

        .add-category-button {
            width: 100%;
            font-size: 0.9rem;
            margin-top: 1rem;
        }
    }
}
