.search {
    .search-content {
        // background-color: #f1f5f8;
        // padding-top: 4rem;
        // padding-bottom: 2rem;
        padding: 8rem 0 2rem;
        background-color: #ebebeb;
        // border-bottom-right-radius: 200px;

        .search-box {
            .search-title {
                font-weight: 700;
                margin: 0;
                // font-size: 1.3rem;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                margin-bottom: 2rem;
            }

            .search-subtitle {
                margin-bottom: 2rem;
            }

            .search-input-container {
                padding: 0.5rem;
                padding-top: 1rem;
                background: #ffffff;
                box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.14);
                border-radius: 6px;
            }
        }
    }

    .feature-section {
        margin-top: 3rem;

        .feature-title-section {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 1rem;

            .feature-title {
                font-weight: 500;
                font-size: 1.2rem;
                color: #000000;
                margin-bottom: 13px;
            }

            .feature-subtitle {
                font-size: 1rem;
            }
        }

        .feature-card-section {
            margin-top: 80px;
            display: flex;
            justify-content: space-between;
        }
    }

    .search-result {
        padding-top: 1rem;

        .search-result-text {
            margin-bottom: 2rem;
            font-size: 1.3rem;
        }

        .search-result-header {
            padding: 20px;
            background-color: #f6f8fa;
            display: flex;
            flex-direction: column;
            border-radius: 6px;

            .search-result-location {
                margin-bottom: 1rem;

                .location-short {
                    font-weight: 500;
                    font-size: 1.1rem;
                    line-height: 28px;
                    color: #000000;
                    margin-bottom: 0.2rem;
                }

                .location-long {
                    font-size: 0.9rem;
                    line-height: 19px;
                    color: #000000;
                }
            }

            .search-result-stats {
                background-color: #fff;
                padding: 35px 40px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .result-count {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000000;
                }

                .result-text {
                    font-weight: 400;
                    font-size: 0.9rem;
                    line-height: 15px;
                    margin-top: 10px;
                    color: #454749;
                }
            }
        }

        .search-result-content {
            .search-result-section {
                display: grid;
                gap: 0px 40px;
                grid-template-columns: auto;
            }

            .search-result-pagination {
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;

                .page-link {
                    color: #4576ff !important;
                }
                .active > .page-link,
                .page-link.active {
                    background-color: #4576ff !important;
                    border-color: #4576ff !important;
                    color: #fff !important;
                }
            }

            .search-result-pagination-skeleton {
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;

                div {
                    display: block;
                    width: 40%;
                }
            }
        }
    }

    .no-results {
        display: flex;
        justify-content: center;
        margin: 2rem 0;
        font-size: 1rem;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .search {
        .search-content {
            padding: 8rem 4rem 2rem 4rem;
        }

        .search-result {
            .search-result-header {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .search {
        .search-content {
            padding: 8rem 6rem 3rem 6rem;
            border-bottom-right-radius: 200px;

            .search-input-container {
                padding: 0.5rem;
            }

            .search-title {
                font-weight: 500;
                font-size: 1.6rem;
            }
        }

        .search-result {
            .search-result-text {
                margin-top: 3rem;
                font-size: 1.5rem;
            }

            .search-result-header {
                .search-result-location {
                    .location-short {
                        font-size: 1.5rem;
                    }

                    .location-long {
                        font-size: 1rem;
                    }
                }

                .search-result-stats {
                    .result-text {
                        font-size: 1rem;
                    }
                }
            }

            .search-result-content {
                .search-result-section {
                    grid-template-columns: auto auto auto;
                    padding: 10px;
                }
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .search {
        .search-content {
            padding: 3rem 6rem 4rem 6rem;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .search-box {
                .search-title {
                    font-weight: 500;
                    font-size: 30px;
                    margin-top: 150px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: left;
                    // margin-bottom: 2rem;
                }

                .search-input-container {
                    padding: 0.5rem;
                    background: #ffffff;
                    box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.14);
                    border-radius: 6px;
                }
            }
        }

        .search-result {
            .search-result-text {
                margin-bottom: 2rem;
            }
        }

        .no-results {
            margin-bottom: 3rem;
        }
    }
}
