.security-section {
    .section-header {
        margin-top: 2rem;
        font-size: 1rem;
        border-bottom: 1px solid #ebebeb;
    }

    .section-title {
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 0rem;
    }

    .section-subtitle {
        font-size: 0.9rem;
    }

    .content-section {
        margin-top: 2rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #ebebeb;

        .input-label-text {
            font-weight: 400;
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
        }

        input {
            font-size: 0.9rem;
        }

        .update-button {
            font-size: 0.9rem;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    .delete-section {
        margin-top: 2rem;
        padding-bottom: 3rem;

        .section-subtitle {
            font-size: 0.9rem;
        }

        .delete-button {
            background-color: #f00;
            color: #fff;
            font-size: 0.96rem;

            &:hover {
                background-color: #f00;
                color: #fff;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .security-section {
        .section-title {
            font-size: 1.1rem;
        }

        .section-subtitle {
            font-size: 1rem;
        }

        .content-section {
            margin-top: 2rem;
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .security-section {
        .section-title {
            font-size: 1.1rem;
        }

        .section-subtitle {
            font-size: 1rem;
        }

        .content-section {
            margin-top: 2rem;

            .input-label-text {
                font-size: 1rem;
            }

            input {
                font-size: 1rem;
            }

            .update-button {
                font-size: 1rem;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}
