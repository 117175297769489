.review-item-parent {
    position: relative;

    .maskContent {
        -webkit-mask-image: linear-gradient(black, transparent);
        mask-image: linear-gradient(black, transparent);
    }

    .masked-button-box {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .review-item {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        padding: 1rem;
        margin-bottom: 1rem;

        .review-box-mobile {
            .review-top-section {
                display: flex;
            }

            .review-content {
                width: 100%;
            }

            .user-image {
                background-color: #f6f8fa;
                padding: 1rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 70px;
                height: 50px;
                margin-right: 1rem;
            }

            .user-name {
                font-size: 1rem;
                margin-bottom: 0.3rem;
            }

            .posted-time {
                font-size: 0.8rem;
            }

            .rating {
                margin-top: 0.2rem;

                div {
                    span {
                        font-size: 0.9rem !important;
                    }
                }
            }

            .review-comment {
                margin-top: 0.5rem;
                font-size: 0.9rem;
            }

            .review-list-preview {
                margin-top: 0.5rem;

                .thumbsContainer {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 16px;
                }

                .thumb {
                    display: inline-flex;
                    border-radius: 2px;
                    border: 1px solid #eaeaea;
                    margin-bottom: 8px;
                    margin-right: 8px;
                    width: 100px;
                    height: 100px;
                    padding: 4px;
                    box-sizing: border-box;
                }

                .thumbInner {
                    display: flex;
                    min-width: 0;
                    overflow: hidden;
                    position: relative;

                    .remove-button {
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-color: #ebebeb;
                        padding: 0.2rem 0.5rem;
                        border-bottom-left-radius: 6px;

                        &:hover {
                            cursor: pointer;
                            background-color: #d66060;
                            color: #fff;
                        }
                    }
                }

                .img {
                    display: block;
                    width: auto;
                    height: 100%;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .read-more {
                color: #4576ff;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .review-item-parent {
        .review-item {
            .review-box-mobile {
                width: 100%;

                .review-top-section {
                    width: 100%;
                    display: flex;

                    .review-header {
                        .top-content {
                            display: flex;
                            justify-content: space-between;

                            .user-name {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .review-box {
                display: flex !important;
                width: 100%;

                .review-top-section {
                    display: flex;
                }

                .review-content {
                    width: 100%;

                    .top-content {
                        display: flex;
                        justify-content: space-between;

                        .user-name {
                            font-size: 1rem;
                            margin-bottom: 0;
                        }
                    }
                }

                .user-image {
                    background-color: #f6f8fa;
                    padding: 1rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 70px;
                    height: 50px;
                    margin-right: 1rem;
                }

                .posted-time {
                    font-size: 0.8rem;
                }

                .rating {
                    margin-top: 0.2rem;

                    div {
                        span {
                            font-size: 0.9rem !important;
                        }
                    }
                }

                .review-comment {
                    margin-top: 0.5rem;
                    font-size: 0.9rem;
                }

                .review-list-preview {
                    margin-top: 0.5rem;
                }

                .read-more {
                    color: #4576ff;
                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .review-item-parent {
        .review-item {
            display: flex;
            justify-content: space-between;
            border: 1px solid #ebebeb;
            border-radius: 6px;
            padding: 2rem 1rem;
            padding-bottom: 1rem;
            margin-bottom: 1rem;

            .review-box {
                .review-content {
                    width: 100%;
                }

                .user-image {
                    background-color: #f6f8fa;
                    padding: 1rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80px;
                    height: 65px;
                    margin-right: 1rem;
                }

                .rating {
                    div {
                        span {
                            font-size: 1rem !important;
                        }
                    }
                }

                .review-comment {
                    margin-top: 1rem;
                    font-size: 1rem;
                }

                .top-content {
                    display: flex;
                    justify-content: space-between;

                    .posted-time {
                        font-size: 14px;
                        color: #9b9699;
                    }
                }

                .review-list-preview {
                    margin-top: 1rem;
                }

                .read-more {
                    color: #4576ff;
                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
