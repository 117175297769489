.home {
    padding: 8rem 0 0 0;

    .home-title {
        font-size: 34px;
        font-weight: 600;
        color: #262626;
        line-height: 45px;
        margin-bottom: 1rem;
    }

    .home-subtitle {
        font-size: 18px;
        line-height: 40px;
        color: #4d4d4d;
        font-weight: 400;
        margin-bottom: 2rem;
    }

    .home-search-button {
        padding: 1rem 2rem;
        font-weight: 500;
        border-radius: 300px;
        background-color: #4576ff;
    }

    .home-image {
        margin-top: 4rem;
        width: 400px;
    }
}

.features {
    // margin-top: 5rem;
    margin-bottom: 3rem;
    // background-color: #fff;

    .feature-header-section {
        margin-top: 6rem;

        .feature-header-title {
            font-size: 23px;
            line-height: 2rem;
        }

        .feature-header-content {
            font-size: 18px;
            margin-top: 1rem;
            line-height: 2.3rem;
        }
    }

    .feature-item {
        margin-top: 5rem;

        .feature-title {
            font-size: 23px;
            line-height: 2rem;
        }

        .feature-subtitle {
            font-size: 1.1rem;
            line-height: 2rem;
            margin-top: 1rem;
            // margin-right: 7rem;
        }

        .image-box {
            display: flex;
            justify-content: center;

            .postbox-image,
            .review-image {
                height: 300px;
                width: 400px;
            }
        }

        .feature-content-mobile {
            margin-bottom: 2rem;
        }
    }
}

@media (min-width: 768px) {
    .home {
        .home-image {
            width: 100%;
        }
    }
}

@media (min-width: 992px) {
    .features {
        .feature-item {
            .feature-content-right {
                margin-left: 3rem;
            }
        }
    }
}

@media (min-width: 1200px) {
    .home {
        padding: 10rem 0 0 0;

        .home-title {
            font-size: 63px;
            font-weight: 600;
            color: #262626;
            line-height: 79px;
            margin-bottom: 2rem;
        }

        .home-subtitle {
            font-size: 22px;
            line-height: 40px;
            color: #4d4d4d;
            font-weight: 400;
            padding-right: 2rem;
        }

        .home-search-button {
            margin-top: 3rem;
            padding: 1.3rem 2.171rem;
            font-weight: 500;
            border-radius: 300px;
            background-color: #4576ff;
        }
    }

    .features {
        margin-top: 5rem;
        margin-bottom: 5rem;
        background-color: #fff;

        .feature-header-section {
            margin-top: 6rem;

            .feature-header-title {
                font-size: 2.1rem;
                line-height: 3rem;
            }

            .feature-header-content {
                font-size: 19px;
                margin-top: 1rem;
                line-height: 2.3rem;
            }
        }

        .feature-item {
            margin-top: 5rem;

            .feature-title {
                font-size: 1.8rem;
            }

            .feature-subtitle {
                font-size: 1.1rem;
                line-height: 2.5rem;
                margin-top: 1rem;
                margin-right: 7rem;
            }
        }
    }
}
