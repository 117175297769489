.review-popup-content {
    .header {
        display: flex;
        margin-top: 1rem;

        .user-image {
            background-color: #f6f8fa;
            padding: 1rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 55px;
            margin-right: 1rem;
        }

        .user-name {
            font-size: 1rem;
        }

        .posted-time {
            font-size: 0.85rem;
        }

        .top-content {
            display: flex;
            flex-direction: column;
        }
    }

    .rating {
        margin-top: 1rem;
        
        div {
            span {
                font-size: 1rem !important;
            }
        }
    }

    .comment {
        margin: 1rem 0;
        font-size: 0.97rem;
    }
}

.image-box {
    .image-gallery {
        background-color: #f8f8f8 !important;
    }
}
