.settings {
    padding: 3rem 0;

    .settings-header {
        margin-top: 3rem;

        .settings-title {
            font-size: 1rem;
            font-weight: 500;
        }

        .settings-subtitle {
            margin-top: 0.5rem;
            font-size: 0.9rem;
        }
    }

    .settings-tab-section {
        margin-top: 1rem;

        .nav-tabs {
            .nav-item {
                .nav-link {
                    color: #454749;
                }

                .nav-link.active {
                    font-size: 1rem !important;
                    color: #4576ff !important;
                }
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .settings {
        .settings-header {
            .settings-title {
                font-size: 1.2rem;
            }

            .settings-subtitle {
                font-size: 1rem;
            }
        }

        .settings-tab-section {
            margin-top: 2rem;
        }
    }
}